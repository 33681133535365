<template>
  <div>
    <b-card :title="$t('master.department.search')">
      <b-form @submit.prevent="searchData">
        <b-row>
          <b-col md="6" lg="4">
            <b-form-group :label="$t('master.department.name')">
              <b-form-input v-model="search.departmentName"/>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group :label="$t('master.department.name')">
              <b-form-input v-model="search.departmentEmail"/>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group :label="$t('master.department.name')">
              <b-form-input v-model="search.departmentTel"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center">
            <b-button type="submit" variant="info" class="my-1 mr-1">
              <feather-icon icon="SearchIcon"/>
              {{ $t('general.btnSearch') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>

    </b-card>

    <b-row>
      <b-col class="text-right">
        <b-button
            type="button"
            variant="primary"
            :to="{name: 'pageMasterTemplateAdd'}"
            class="mb-2"
        >
          <feather-icon icon="PlusCircleIcon"/>
          {{ $t('general.btnAdd') }}
        </b-button>
      </b-col>
    </b-row>

    <b-overlay :show="isShowLoading">
      <b-card :title="$t('master.department.list')">
        <!-- table -->
        <vue-good-table
            mode="remote"
            :columns="tableData.columns"
            :rows="tableData.rows"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="tableData.totalRecords"
            :isLoading.sync="tableData.isLoading"
            :pagination-options="{ enabled: true }"
        >
          <template
              slot="table-row"
              slot-scope="props"
          >
            <div v-if="props.column.field === 'action'" class="text-nowrap text-center">
              <b-button
                  type="button"
                  variant="warning"
                  :to="{name: 'pageMasterTemplateEdit', params: {id: props.row.departmentId}}"
                  size="sm"
                  class="mr-1"
              >
                <feather-icon icon="Edit2Icon"/>
              </b-button>
              <b-button
                  type="button"
                  variant="danger"
                  size="sm"
                  @click="deleteData(props.row.departmentId)"
              >
                <feather-icon icon="Trash2Icon"/>
              </b-button>
            </div>

            <div v-else>{{ props.formattedRow[props.column.field] }}</div>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('general.tableShowing') }} 1 {{ $t('general.tableTo') }}
            </span>
                <b-form-select
                    v-model="tableData.pageLength"
                    :options="tableData.pageLengthOption"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> {{ $t('general.tableOf') }} {{ props.total }} {{ $t('general.tableEntries') }} </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="tableData.pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {BRow, BCol, BCard, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BForm, BOverlay} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import tableMixins from "@/mixins/tableMixins"
import {TemplateService} from "@/services"

const departmentService = new TemplateService()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    VueGoodTable
  },
  mixins: [tableMixins],
  created() {
    this.tableData.columns = [
      {
        label: this.$t('master.department.name'),
        field: 'departmentName',
        width: '20%',
        sortable: true
      }, {
        label: this.$t('general.tableUpdateDate'),
        field: 'actionDate',
        width: '20%',
        sortable: false
      }, {
        label: this.$t('general.tableUpdateBy'),
        field: 'actionBy',
        width: '20%',
        sortable: false
      }, {
        label: '',
        field: 'action',
        width: '10%',
        sortable: false
      },
    ]
    this.listData()
  },
  data: () => ({
    isShowLoading: true,
    pk: '',
    search: {
      departmentName: '',
      departmentEmail: '',
      departmentTel: ''
    },
  }),
  computed: {},
  watch: {
    tableChange() {
      this.listData()
    }
  },
  methods: {
    listData() {
      this.getTableQueryString()
      departmentService.listData(this.tableQueryString).then(queryResult => {
        let {isSuccess, data} = queryResult
        if (isSuccess) {
          this.tableData.rows = data.results
          this.tableData.totalRecords = data.totalRow
        } else {
          this.$store.commit('main/setToastError', data)
        }
        this.isShowLoading = false
      })
    },
    searchData() {
      this.onColumnFilter(this.search)
    },
    deleteData(id) {
      this.$bvModal
          .msgBoxConfirm(this.$t('general.deleteDescription'), {
            title: this.$t('general.deleteTitle'),
            okVariant: 'primary',
            okTitle: this.$t('general.deleteConfirm'),
            cancelTitle: this.$t('general.deleteCancel'),
            cancelVariant: 'outline-secondary',
          })
          .then(async value => {
            if (value) {
              let queryResult = await departmentService.deleteData(id)
              let {isSuccess, data} = queryResult
              if (isSuccess) {
                this.isShowLoading = true
                this.listData()
                this.$store.commit('main/setToastSuccess', {title: 'Delete success', text: ''})
              } else {
                this.$store.commit('main/setToastError', data)
              }
            }
          })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

